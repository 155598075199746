<template>
    <component 
        v-if="authConfig" 
        :is="formWidget" 
        :saveForm="saveForm"
        :regForm="regForm" 
        :setRegForm="setRegForm" 
        :resetRegForm="resetRegForm" />
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    computed: {
        authConfig() {
            return this.$store.state.user.authConfig
        },
        regStep() {
            return this.$store.state.user.regStep
        },
        formWidget() {
            switch (this.regStep) {
            case 1:
                return () => import('./InputBIN.vue')
            case 2:
                return () => import('./StartForm.vue')
            case 3:
                return () => import('./UserInfo.vue')
            case 4:
                return () => import('./ThanksForRegistration.vue')
            case 5:
                return () => import('./CheckPassword.vue')
            case 6:
                return () => import('./UserInfo.vue')
            default:
                return () => import('./StartForm.vue')
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t('auth.portal_reg_title')
        }
    },
    data() {
        return {
            formType: 1,
            regForm: {
                login: '',
                target: '',
                confirm_token: ''
            },
            saveForm: {
                password: '',
                password_confirm: '',
                contractor_bin: '',
                contractor_name: '',
                contractor_id: '',
                typeInput: '',
                login: '',
                isInvite: false,
            }
        }
    },
    methods: {
        setRegForm(form) {
            this.regForm = form
        },
        resetRegForm() {
            this.regForm = {
                login: '',
                target: '',
                confirm_token: ''
            }
        }
    },
    mounted() {
        eventBus.$on('set_contractor_for_register', ({ contractor }) => {
            this.saveForm.contractor_id = contractor.id
            this.saveForm.isInvite = true
        })
    },
    beforeDestroy() {
        eventBus.$off('set_contractor_for_register')

        this.$store.commit('user/SET_REG_STEP', 1)
    }
}
</script>

<style lang="scss" scoped>
  .translate-enter-active,
  .translate-leave-active {
    transition: all 0.5s ease;
  }
  .translate-enter-from,
  .translate-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>